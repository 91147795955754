import React from "react"
import SEO from "../../components/seo"
import { Footer, FooterLinks } from "../../components/Layout/Footer"
import Layout from "../../components/Layout"
import FullEpisodeList from "../../components/Episode/FullList"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"

const EpisodesPage = () => (
  <Layout>
      <SEO title={`Episodes`} />
      <Container style={{ paddingBottom: '20vh', paddingTop: 16 }}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} md={4}>
            <Typography variant="h4" gutterBottom>
              Home
            </Typography>
          </Grid> */}
          <Grid item xs={12} md={12}>
            <Typography variant="h4" gutterBottom>
              Episodes
            </Typography>
            <FullEpisodeList />
          </Grid>
        </Grid>
      </Container>
      <Footer />
      <FooterLinks />
    </Layout>
)

export default EpisodesPage
